/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as builder from "../../ducks/builder";
import { CMSService } from '../../../app/services';
class Brand extends React.Component {
  state = {image: ''}

   loadFromCMS = async () => {
    try {
      const [image] = await Promise.all([
        CMSService.getContentFromYear('logo-admin', 'slug,title,metadata')
      ]);

      const logourl = image?.metadata?.logo?.url;
      this.setState({image: logourl});
    } catch (e) {
      console.error(e);
    }
  }



  componentDidMount() {
    this.loadFromCMS();
  }

  render() {
    return (
      <div
        className={`kt-aside__brand kt-grid__item ${this.props.brandClasses}`}
        id="kt_aside_brand"
      >
        <div className="kt-aside__brand-logo">
          <Link to="">
            <img alt="logo" src={this.state.image} />
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    brandClasses: builder.selectors.getClasses(store, {
      path: "brand",
      toString: true
    })
  };
};

export default connect(mapStateToProps)(Brand);
