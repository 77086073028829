import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  ModalDesafioIsOpenSet: "[ModalDesafioIsOpen.Set] Action",
  ModalDesafioOnCompleteSet: "[ModalDesafioOnComplete.Set] Action"
};

const INITIAL_STATE = {
  desafioId: 0,
  isOpen: false,
  onComplete: null
}

export const modalDesafioReducer = persistReducer(
  { storage, key: "modalDesafio" },
  (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case actionTypes.ModalDesafioIsOpenSet: {
        return { ...state, isOpen: action.payload.isOpen, desafioId: action.payload.desafioId };
      }
      case actionTypes.ModalDesafioOnCompleteSet: {
        return { ...state, onComplete: action.payload };
      }
      default:
        return state;
    }
  }
);

export const ModalDesafioActions = {
  setIsOpen: (isOpen, desafioId) => ({ type: actionTypes.ModalDesafioIsOpenSet, payload: {isOpen, desafioId} }),
  setOnComplete: onComplete => ({ type: actionTypes.ModalDesafioOnCompleteSet, payload: onComplete }),
};