export default () => {
  const isB2b = process.env.REACT_APP_IS_B2B;

  const menu = {
    header: {
      self: {},
      items: [
        {
          title: "Dashboards",
          root: true,
          alignment: "left",
          page: "dashboard",
          translate: "MENU.DASHBOARD"
        }
      ]
    },
    aside: {
      self: {},
      items: []
    },
  };

  menu.aside.items.push({
    title: "Dashboard",
    root: true,
    icon: "flaticon-analytics",
    page: "admin/dashboard",
    translate: "MENU.DASHBOARD",
    bullet: "dot"
  });

  isB2b === 'false' && menu.aside.items.push({
    title: "Perfis",
    root: true,
    icon: "flaticon-avatar",
    page: "admin/perfis",
    translate: "MENU.PROFILES",
    bullet: "dot"
  });

  menu.aside.items.push({
    title: "Usuários",
    root: true,
    icon: "flaticon-users",
    bullet: "dot",
    submenu: [
      {
        title: "Gerenciar Usuários",
        page: "admin/usuarios"
      },
      {
        title: "Treinamentos e Módulos",
        page: "admin/gerenciar-treinamentos-modulos"
      },
      {
        title: "Importação em Lote",
        page: "admin/importacoes?type=USER"
      }
    ]
  });

  menu.aside.items.push({
    title: "Desafios",
    root: true,
    icon: "flaticon-edit-1",
    bullet: "dot",
    submenu: [
      {
        title: "Gerenciar Desafios",
        page: "admin/desafios"
      },
      {
        title: "Avaliação de Respostas",
        page: "admin/respostas"
      }
    ]
  });

  menu.aside.items.push({
    title: "Aulas",
    root: true,
    icon: "flaticon-presentation-1",
    bullet: "dot",
    submenu: [
      {
        title: "Gerenciar Aulas",
        page: "admin/aulas"
      },
      {
        title: "Módulos",
        page: "admin/modulos"
      },
      {
        title: "Carreiras",
        page: "admin/carreiras"
      },
      {
        title: "Treinamentos",
        page: "admin/treinamentos"
      },
      isB2b === 'false' && {
        title: "Períodos",
        page: "admin/periodos"
      },
      isB2b === 'false' && {
        title: "Turmas",
        page: "admin/turmas"
      }
    ]
  });

  menu.aside.items.push({
    title: "Conquistas",
    root: true,
    icon: "flaticon-trophy",
    page: "admin/conquistas",
    bullet: "dot",
  });

  menu.aside.items.push({
    title: "Regras de Pontuação",
    root: true,
    icon: "flaticon-interface-6",
    page: "admin/regras",
    bullet: "dot"
  });

  menu.aside.items.push({
    title: "Pontuação Avulsa",
    root: true,
    icon: "flaticon-gift",
    page: "admin/pontuacao-avulsa",
    bullet: "dot"
  });

  menu.aside.items.push({
    title: "Enviar E-mails",
    root: true,
    // icon: "flaticon-mail-1",
    icon: "flaticon-paper-plane",
    page: "admin/emails",
    bullet: "dot"
  });

  isB2b === 'false' &&  menu.aside.items.push({
    title: "Loja",
    root: true,
    icon: "flaticon-bag",
    bullet: "dot",
    submenu: [
      {
        title: "Produtos",
        page: "admin/produtos"
      },
      {
        title: "Tamanho Produtos",
        page: "admin/produtos-tamanhos"
      },
      {
        title: "Transações",
        page: "admin/transactions",
      }
    ]
  });

  return menu;
}


