import React from 'react';
import { CMSService } from '../../services';

import { LoadingComponent } from './styles';

function LoadingPage(){
    const [logo, setLogo] = React.useState('');
    
    async function loadFromCMS (){
        const [img] = await Promise.all([
            CMSService.getContentFromYear('logo-light', 'slug,title,metadata')
        ]);

        setLogo(img?.metadata?.logo?.url);
    };

      React.useEffect(() => {
        loadFromCMS();
      }, []);

    return(
        <LoadingComponent >
            <img src={logo} alt="Devaria logo" />
            <svg className="spinner" viewBox="0 0 50 50">
                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth={5}></circle>
            </svg>
        </LoadingComponent>
    );
}

export { LoadingPage };