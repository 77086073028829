import {createBucketClient} from '@cosmicjs/sdk';
import { setData, getData } from '../utils/CacheStorage';


const bucket = createBucketClient({
  bucketSlug: process.env.REACT_APP_COSMICJS_BUCKET_SLUG,
  readKey: process.env.REACT_APP_COSMICJS_BUCKET_READ_KEY,
})
class CMSService {
  static getContentFromYear(slug, props = 'slug,title,content', cached = true) {
    slug = `${slug}`;
    return this.getContent(slug, props, cached);
  }

  static getMediaFromYear(folder, props = 'url') {
    folder = `${folder}`;
    return this.getMedia(folder, props);
  }

  static async getContent(slug, props = 'slug,title,content', cached = true) {
    let response = cached
      ? getData(slug)
      : null;


    if (response !== null && response !== undefined && response !== '' && response !== 'undefined') {
      return JSON.parse(response);
    }

    response = await bucket.objects.findOne({
      slug,
      props
    });

    if (cached) {
      setData(slug, JSON.stringify(response.object));
    }

    return response.object;
  }

  static async getMedia(folder, props = 'url') {
    const response = await bucket.media.find({
      folder,
      props
    });

    return response.media && response.media.length === 1
      ? response.media[0]
      : response.media;
  }
}

export default CMSService;
