/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/AdminPage`).
 */

import React, { lazy, Suspense, useContext } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import { Redirect, Route, Switch, useHistory, useLocation, withRouter } from "react-router-dom";
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import ThemeContext from '../context/theme';
import Layout from "../../_metronic/layout/Layout";
import { LayoutContextProvider } from "../../_metronic";
import * as routerHelpers from "../router/RouterHelpers";
import { LoadingPage } from "../components/LoadingPage";


const AdminPage = lazy(() => import("../pages/admin/AdminPage"));
const LogoutPage = lazy(() => import("../pages/auth/Logout"));
const AuthPage = lazy(() => import("../pages/auth/AuthPage"));
const Login = lazy(() => import("../pages/sessionStudent/Login"));
const Register = lazy(() => import("../pages/sessionStudent/Register"));
const ForgotPassword = lazy(() => import("../pages/sessionStudent/ForgotPassword"));
const RecoverOrRegisterPassword = lazy(() => import("../pages/sessionStudent/RecoverOrRegisterPassword"));
const Profile = lazy(() => import("../pages/profile/ProfileHome"));
const SorryPage = lazy(() => import("../pages/sorry"));
const DevStars = lazy(() => import("../pages/devStars/DevStarsHome"));
const PrivacyPolicyPage = lazy(() => import("../pages/policy/PrivacyPolicyPage"));
const ProfilePublic = lazy(() => import("../pages/profile/ProfilePublic"));
const BadgeValidation = lazy(() => import("../pages/profile/ProfilePublic/BadgeValidation"));
const Aulas = lazy(() => import("../pages/aulas"));
const Lives = lazy(() => import("../pages/live"));
const TrainingsListing = lazy(() => import("../pages/aulas/trainingListing").then(module => ({ default: module.TrainingsListing })));
const Training = lazy(() => import("../pages/aulas/details/training").then(module => ({ default: module.Training })));
const ModuleListing = lazy(() => import("../pages/aulas/modulesListing").then(module => ({ default: module.ModuleListing })));
const Modulo = lazy(() => import("../pages/aulas/moduleStudent").then(module => ({ default: module.Modulo })));
const CareerList = lazy(() => import("../pages/aulas/careerList").then(module => ({ default: module.CareerList })));
const Career = lazy(() => import("../pages/aulas/details/career").then(module => ({ default: module.Career })));
const NotFoundPage = lazy(() => import("../pages/errors/notFound").then(module => ({ default: module.NotFoundPage })));

export const RoutesB2b = withRouter(() => {
  const history = useHistory();
  const { themeSelect } = useContext(ThemeContext);
  const location = useLocation();
  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  const { isAuthorized, menuConfig, userLastLocation } = useSelector(
    ({ auth, urls, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: auth.user != null,
      userLastLocation: routerHelpers.getLastLocation()
    }),
    shallowEqual
  );

  const nameDevstars = process.env.REACT_APP_DEVSTARS_NAME || 'Devstars'

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <StyledThemeProvider theme={themeSelect}>
      <LayoutContextProvider history={history} menuConfig={menuConfig}>
        {process.env.SHOW_SORRY_PAGE === 'true'
          ? <SorryPage />
          : (
            <Suspense fallback={<LoadingPage />}>
              <Switch>
                <Route path="/auth"><AuthPage /></Route>
                <Route path="/auth/login"><AuthPage /></Route>

                <Route path="/logout"><LogoutPage /></Route>

                <Route exact path="/entrar"><Login /></Route>
                <Route exact path="/entrar/:type/:redirectId"><Login /></Route>

                <Route exact path="/cadastro"><Register /></Route>
                <Route exact path="/registro-concluido"><Register /></Route>
                <Route exact path="/cadastro/:type/:redirectId"><Register /></Route>
                <Route exact path="/cadastro/:name/:emailGoogle/:googleId"><Register /></Route>
                
                <Route path="/esqueci-senha"><ForgotPassword /></Route>
                <Route path="/privacy-policy"><PrivacyPolicyPage /></Route>
                <Route path="/not-found"><NotFoundPage /></Route>

                {/* Validate Badges */}
                <Route exact path="/conquistas/:badge/:user"><BadgeValidation /></Route>

                {/* Fim Submenu */}
                <Route path="/ativar-conta"><RecoverOrRegisterPassword /></Route>
                <Route path="/recuperar-senha"><RecoverOrRegisterPassword /></Route>
                <Route path="/cadastrar-senha"><RecoverOrRegisterPassword /></Route>


                {/* AULAS */}
                <Route exact path="/assistir/:type/:id"><Aulas /></Route>

                {isAuthorized ? (
                  <>
                    <Route path="/admin">
                      <Layout>
                        <AdminPage userLastLocation={userLastLocation} />
                      </Layout>
                    </Route>
                    <Route exact path='/live/:event/:id'><Lives /></Route>
                    <Route exact path='/perfil'><Profile /></Route>
                    <Route exact path="/perfil/:id"><ProfilePublic/></Route>

                    {/* Submenu */}
                    <Route exact path="/carreiras/:id"><Career /></Route>
                    <Route exact path="/treinamentos"><TrainingsListing /></Route>
                    <Route exact path="/treinamentos/:training"><Training /></Route>
                    <Route path={`/${nameDevstars}`}><DevStars/></Route>
                    <Route exact path="/"><CareerList /></Route>
                    {/* <Redirect to="/" state={{ from: location }} /> */}
                  </>
                ) : (
                  <Redirect from="/" to="/entrar" />
                )}
                <Route path="/"><CareerList /></Route>
              </Switch>
            </Suspense>
          )}
      </LayoutContextProvider>
    </StyledThemeProvider>
  );
});
