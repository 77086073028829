import React, { useEffect, useState } from "react";
import Icon from "@material-ui/core/Icon/";
import { CMSService } from "../../../../app/services";
import { Aside } from "./styles";
import MenuConfig from "../../MenuConfig";
import { Link } from "react-router-dom";

function AsideAdmin() {
  const [logo, setLogo] = useState();
  const { aside, header } = MenuConfig();

  async function loadFromCMS() {
    try {
      const [image] = await Promise.all([
        CMSService.getContentFromYear("logo-admin", "slug,title,metadata")
      ]);

      const logourl = image?.metadata?.logo?.url;
      setLogo(logourl);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    loadFromCMS();
  }, []);

  return (
    <Aside>
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>

      <div className="links">
        {aside.items.map((i, index)=>
          !i.submenu ? (
            <Link key={index} to={`/${i.page}`} className={window.location.pathname === '/'+i.page ? 'selected' : ''}>
              <i className={i.icon} />
              <span>{i.title}</span>
            </Link>
          ) : (
            <div className="submenu" key={index} >
              
                <div className={`submenu-title ${window.location.pathname === '/'+i.submenu.find(s => '/'+s.page === window.location.pathname)?.page && 'selected'}`}>
                  <i className={i.icon} />
                  <span>{i.title}</span>
                </div>

                <div className="submenu-itens">
                  {i.submenu.map(s => (
                    s && <Link to={`/${s.page}`} className={window.location.pathname === '/'+s.page ? 'submenu-selected' : ''}>
                      <span>{s.title}</span>
                    </Link>
                  ))}
                </div>
              
            </div>
          )
        )}
      </div>
    </Aside>
  );
}

export { AsideAdmin };